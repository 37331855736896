<template>
  <section class="pd-20">
    <a-page-header style="padding: 0" sub-title="">
      <div slot="title">
        <h1 class="mb-0">Usuários</h1>
      </div>
      <div slot="extra">
        <a-button
          type="primary"
          ghost
          icon="user-add"
          @click="showDrawer('create')"
        >
          Novo Usuário
        </a-button>

        <a-drawer
          width="700"
          size="small"
          :closable="true"
          :destroyOnClose="true"
          :visible="createUser || editUser"
          placement="right"
          @close="onClose"
        >
          <div slot="title">
            <h2 v-show="createUser" class="mb-0">
              <a-icon type="user-add" /> Cadastrar usuário
            </h2>
            <h2 v-show="editUser" class="mb-0">
              <a-icon type="edit" /> Editar usuário
            </h2>
          </div>

          <CreateUser v-if="createUser" @userSubmitted="userSubmitted" />

          <EditUser
            v-if="editUser"
            :userData="userData"
            @userSubmitted="userSubmitted"
          />
        </a-drawer>
      </div>
    </a-page-header>

    <a-collapse
      class="travel-filters expandable mt-20 mb-10"
      activeKey="0"
      expandIconPosition="right"
    >
      <a-collapse-panel key="1">
        <template slot="header"> <a-icon type="filter" /> FILTRAR </template>
        <a-row :gutter="20">
          <a-col :span="6">
            <div class="travel-input">
              <label for class="filled">Status</label>
              <a-select
                class="travel-input"
                placeholder="Selecione o status do contrato"
                mode="multiple"
                v-model="user.filters.status.selected"
                show-search
                style="width: 100%"
                @change="getUsers()"
              >
                <a-select-option
                  v-for="(item, index) of user.filters.status.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>

          <a-col :span="6">
            <div class="travel-input">
              <label for class="filled">Função</label>
              <a-select
                class="travel-input"
                placeholder="Selecione o status do contrato"
                mode="multiple"
                v-model="user.filters.roles.selected"
                show-search
                style="width: 100%"
                @change="getUsers()"
              >
                <a-select-option
                  v-for="(item, index) of user.filters.roles.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>

    <a-table
      class="travel-table"
      :columns="columns"
      :data-source="user.list"
      :loading="user.loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="userTableChange"
    >
      <a slot="id" slot-scope="text">{{ text }}</a>
      <div slot="2fa" slot-scope="record">
        <div v-if="record.twofa_secret">
          <aTooltip>
            <template slot="title"> 2FA ativado </template>
            <aIcon class="cgreen" type="lock" />
            <aIcon class="" type="qrcode" />
          </aTooltip>
        </div>
      </div>
      <div slot="avatar" slot-scope="record">
        <div class="truncate">
          <a-avatar class="mr-10" :size="40" :src="record.avatar" icon="user" />
          {{ record.first_name }} {{ record.last_name }}
        </div>
      </div>

      <a slot="email" slot-scope="text">{{ text }}</a>
      <div slot="role" slot-scope="record">
        {{ record.role | formatUserRole }}
      </div>
      <div slot="status" slot-scope="record">
        {{ formatUserStatus(record.status) }}
      </div>
      <div slot="created" slot-scope="record">
        {{ record.created | formatDateTime }}
      </div>
      <span class="travel-actions" slot="action" slot-scope="record">
        <a class="edit" @click="$router.push(`/users/edit/${record.id}`)">
          <a-icon type="edit-svg" />
        </a>
      </span>
    </a-table>

    <div class="a-center mt-30" style="padding-bottom: 100px">
      <a-pagination
        show-size-changer
        :default-current="user.pagination.page"
        :page-size-options="pageSizeOptions"
        :page-size.sync="user.pagination.perPage"
        :total="user.pagination.total"
        @change="changeUserPage"
        @showSizeChange="changeUserPageSize"
      >
        <template slot="buildOptionText" slot-scope="props">
          <span v-if="props.value !== '99999'">{{ props.value }}/página</span>
          <span v-if="props.value === '99999'"> Todos</span>
        </template>
      </a-pagination>
    </div>
  </section>
</template>

<script>
import _ from "lodash";
import CreateUser from "@/views/users/CreateUser.vue";
import EditUser from "@/views/users/EditUser.vue";
import userMixins from "@/mixins/user/userMixins.js";
import formatThings from "@/mixins/general/formatThings";

export default {
  components: { CreateUser, EditUser },
  mixins: [userMixins, formatThings],
  data() {
    return {
      data: [],
      pageSizeOptions: ["10", "25", "50", "100", "200", "500", "1000"],
      columns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          width: 80,
        },
        {
          title: "2FA",
          key: "2fa",
          scopedSlots: { customRender: "2fa" },
          width: 80,
        },
        {
          title: "",
          key: "avatar",
          scopedSlots: { customRender: "avatar" },
          width: 300,
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
          width: 300,
        },
        {
          title: "Função",
          scopedSlots: { customRender: "role" },
        },
        {
          title: "Status",
          scopedSlots: { customRender: "status" },
          width: 120,
        },
        {
          title: "Data de cadastro",
          scopedSlots: { customRender: "created" },
        },
        {
          title: "",
          key: "action",
          align: "right",
          fixed: "right",

          scopedSlots: {
            customRender: "action",
          },
        },
      ],
      loading: false,
      createUser: false,
      editUser: false,
      userData: {},
    };
  },
  beforeMount() {
    this.user.filters.status.selected = "1";
    this.user.pagination.perPage = 25;
    this.getUsers();

    if (this.$store.state.createUser) {
      this.createUser = true;
    }
  },
  methods: {
    getUser(userId) {
      this.$http
        .post("/user/read_one", {
          id: userId,
        })
        .then(({ data }) => {
          this.userData = data;
          this.editUser = true;
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search: _.debounce(function (e) {
      // console.log(e.target.value);

      this.$http
        .post("/user/search?s=" + e.target.value)
        .then(({ data }) => {
          this.data = data.map((user) => {
            return {
              id: user.id,
              name: `${user.first_name} ${user.last_name}`,
              email: user.email,
              role: this.formatUserRole(user.role),
              status: this.formatUserStatus(user.status),
              created: this.formatDate(user.created),
            };
          });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    }, 600),
    userSubmitted() {
      this.createUser = false;
      this.getUsers();
      this.$store.state.createUser = false;
    },
    showDrawer(type, id) {
      type === "create" ? (this.createUser = true) : (this.createUser = false);

      if (type === "edit") {
        this.getUser(id);
      } else {
        this.editUser = false;
      }
    },
    onClose() {
      this.createUser = false;
      this.editUser = false;
      this.$store.state.createUser = false;
    },
    formatUserStatus(status) {
      return status == 1 ? "Ativo" : "Desativado";
    },

    view(id) {
      this.$router.push(`view/${id}`);
    },
    confirmDelete(userId) {
      this.$http
        .post("/user/delete", {
          id: userId,
        })
        .then(({ data }) => {
          this.$message.success(data.message + "!");
          this.getUsers();
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
  },
};
</script>
